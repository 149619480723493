

const Footer = () => {
  return(
    <footer className='footer-container'>
      <span>Developed by &#169;2023 Alex Graham</span>
    </footer>
  )
}

export default Footer;